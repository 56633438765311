import React from 'react';
import * as styles from './joblink.module.scss';
import { Link } from 'gatsby';
import { Heading } from '../Heading/Heading';
import { Blob } from '../../02_molecules/Blob/Blob';
import * as blobstyles from '../../02_molecules/Blob/blob.module.scss';



function JobLink ({children,title,to,image, ...props}){

    return (
        <div className={styles.joblink}>
            <div className={styles.inner}>
                <Blob
                    video={image && image.format === "mp4" ? image.url : null}
                    image={image && image.format !== "mp4" ? image.url : null}
                >

                    <div className={blobstyles.front}>
                        <Link to={to}><Heading element="h3" size="l" uppercase>{title}</Heading></Link>
                    </div>

                </Blob>
            </div>
        </div>
    );




};

export default JobLink;