import React from "react";
import {graphql} from 'gatsby';
import {useTranslation} from "react-i18next";
import {Heading} from "../components/01_atoms/Heading/Heading";
import {Breadcrumb} from "../components/02_molecules/Breadcrumb/Breadcrumb";
import {BreadcrumbItem} from "../components/02_molecules/Breadcrumb/BreadcrumbItem";
import {Helmet} from "react-helmet";

import Section from "../components/03_organisms/Section/Section";

import * as pages from '../components/03_organisms/Pages/pages.module.scss';
import JobLink from "../components/01_atoms/JobLink/JobLink";
import {PageTransition} from "../components/03_organisms/PageTransition/PageTransition";
import FadeInUp from "../components/01_atoms/FadeInUp/FadeInUp";
import Wrapper from '../components/03_organisms/Wrapper/Wrapper';
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";


const Jobs = ({data, pageContext, ...props}) => {

    // read language
    const {t} = useTranslation();

    // render html
    return (
        <Wrapper>
            <HeadInject />
            <PageTransition>
                <Helmet bodyAttributes={{class: pages.jobs}}/>

                <Section content={false}>
                    <Breadcrumb>
                        <BreadcrumbItem to="/jobs" title="Jobs" active/>
                    </Breadcrumb>
                </Section>

                <Section>
                    <FadeInUp><Heading element="h1" size="xxxl" align="center" spacing="l">{t('jobs_title')}</Heading></FadeInUp>
                    <FadeInUp delay=".25"><Heading element="h2" size="m" align="center" font="main" weight="400">{t('jobs_subtitle')}</Heading></FadeInUp>
                </Section>

                <Section zIndex="10">
                    <FadeInUp delay={.75}>
                        {data.allDatoCmsJob.edges.map(({node: job}, index) => (

                            <JobLink
                                key={index}
                                title={job.title}
                                to={'/jobs/' + job.slug}
                                image={job.hero[0].heroImage}
                            />

                        ))}
                    </FadeInUp>
                </Section>

            </PageTransition>
        </Wrapper>
    )
}

export default Jobs;

export const getJobs = graphql`
query getAllJobsQuery {
	allDatoCmsJob {
		edges {
		node {
			slug
			title
			hero {
			heroImage {
				gatsbyImageData
				url
				width
				height
				alt
				format
			}
			
			seoMetaTags{
				...GatsbyDatoCmsSeoMetaTags
			}
			}
		}
		}
	}
}
`